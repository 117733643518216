import React from "react"
import { Link } from "gatsby"
import { Nav, Dropdown } from "react-bootstrap"
import "./menu.scss"

export default () => (
  <div className="navigation">
    <Nav className="flex-column">
      <Nav.Item>
        <Link to="/">Home</Link>
      </Nav.Item>

      <Dropdown className="nav-item">
        <Link to="/trainings">Trainings</Link>
        <Dropdown.Toggle split variant="success" id="dropdown-split-basic" />
        <Dropdown.Menu>
          <Nav.Item>
            <Link to="/obt2020" target="_blank" rel="noopener noreferrer">
              Online NARM Basics Training
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link to="/online-learning/inner-circle">Inner Circle</Link>
          </Nav.Item>
          <Nav.Item>
            <Link to="/online-learning/videos">Video Training</Link>
          </Nav.Item>
          <Nav.Item>
            <Link to="/schedule">North America Trainings</Link>
          </Nav.Item>
          <Nav.Item>
            <a
              href="http://drlaurenceheller.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              International Trainings
            </a>
          </Nav.Item>
          <Nav.Item>
            <Link to="/trainings/curriculum">NARM Training Curriculum</Link>
          </Nav.Item>
          <Nav.Item>
            <Link to="/trainings/ceus/">CEU's</Link>
          </Nav.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Nav.Item>
        <Link to="/events">Events</Link>
      </Nav.Item>
      <Dropdown className="nav-item">
        <Link to="/resources">Resources</Link>
        <Dropdown.Toggle split variant="success" id="dropdown-split-basic" />
        <Dropdown.Menu>
          <Nav.Item>
            <Link to="/transformingtrauma">Transforming Trauma</Link>
          </Nav.Item>
          <Nav.Item>
            <a
              href="https://narm.kartra.com/page/ebooks"
              target="_blank"
              rel="noopener noreferrer"
            >
              NARM Ebooks
            </a>
          </Nav.Item>
          <Nav.Item>
            <a
              href="https://narm.kartra.com/page/NARMWorksheet"
              target="_blank"
              rel="noopener noreferrer"
            >
              NARM Clinical Protocol & NARM Personality Spectrum Worksheet
            </a>
          </Nav.Item>
          <Nav.Item>
            <Link to="/narm-certificate-completion">
              NARM Training Certificate
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link to="/resources">Podcasts, Books , Articles & More</Link>
          </Nav.Item>
          <Nav.Item>
            <Link to="/blog">Blog</Link>
          </Nav.Item>
          <Nav.Item>
            <Link to="/narm-student-resources">Student Resources</Link>
          </Nav.Item>
          <Nav.Item>
            <a
              href="https://directory.narmtraining.com/"
              target="_blank"
              rel="noreferrer"
            >
              Find a Practitioner
            </a>
          </Nav.Item>
          <Nav.Item>
            <a
              href="/assets/img/pdf/Brand_Policy__(7.3.20).pdf"
              target="_blank"
              rel="noreferrer"
            >
              Brand Policy
            </a>
          </Nav.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown className="nav-item">
        <Link to="/what-is-narm">About Us</Link>
        <Dropdown.Toggle split variant="success" id="dropdown-split-basic" />
        <Dropdown.Menu>
          <Nav.Item>
            <Link to="/what-is-narm">What is NARM?</Link>
          </Nav.Item>
          <Nav.Item>
            <Link to="/faculty">Faculty</Link>
          </Nav.Item>
          <Nav.Item>
            <Link to="/training-assistants">Training Assistants</Link>
          </Nav.Item>
          <Nav.Item>
            <Link to="/staff">Staff</Link>
          </Nav.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Nav.Item>
        <Link to="/contact">Contact</Link>
      </Nav.Item>
    </Nav>
    <div className="mt-4">
      <a
        className="find-practioner"
        href="https://directory.narmtraining.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Find a Practitioner
      </a>
    </div>
  </div>
)
