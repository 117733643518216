import React from "react"
import SideBar from "react-sidebar"
// import { Motion, spring, presets } from "react-motion"
import Menu from "./menu"

class SideBarMenu extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			sidebarOpen: false,
			sidebarpullRight: true,
			toggle: false,
		}

		this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this)
	}
	onSetSidebarOpen(state) {
		this.setState({ sidebarOpen: state.open, toggle: !this.state.toggle })
	}
	toggleMenu() {
		this.setState({
			sidebarOpen: !this.state.sidebarOpen,
			toggle: !this.state.toggle,
		})
	}

	handleKeyDown = ev => {
		// check keys if you want
		if (ev.keyCode === 13) {
			this.focus()
		}
	}

	focus = () => this.ref.focus

	handleRef = component => {
		this.ref = component
	}

	render() {
		// const style = {
		// 	overflow: "visible",
		// 	cursor: "pointer",
		// 	// disable touch highlighting on devices
		// 	WebkitTapHighlightColor: "rgba(0,0,0,0)",
		// }
		return (
			<SideBar
				sidebar={
					<Menu /> // Menu list
				}
				open={this.state.sidebarOpen}
				onSetOpen={this.onSetSidebarOpen}
				pullRight={this.state.sidebarpullRight}
				styles={{
					sidebar: {
						background: "#036cb0",
						width: "300px",
						position: "fixed",
						zIndex: "3",
					},
					root: { position: "relative", overflow: "visible" },
					overlay: { backgroundColor: "rgba(0, 0, 0, 0.65)", zIndex: 2 },
					content: {
						position: "relative",
						overflow: "visible",
						cursor: "pointer",
					},
				}}
			>
				<button 
					className="bm-burger-button float-right text-white"
					onClick={e => this.toggleMenu(e)}
					onKeyDown={e => this.handleKeyDown(e)}
					>
					<span className="mr-1 mt-2 d-none d-md-inline">Menu</span>
					<svg 
							version="1.1" 
							id="Layer_1" 
							xmlns="http://www.w3.org/2000/svg" 
							x="0px" 
							y="0px" 
							width="40px"
							height="40px" 
							viewBox="0 0 45 32.716" 
							style={{ enableBackground: "new 0 0 56 56" }}
							
						>
					  <g>
					    <path fillRule="evenodd" clipRule="evenodd" fill="#fff" d="M42.126,27.322H2.949c-1.353,0-2.449,0.981-2.449,2.192
								s1.096,2.192,2.449,2.192h39.177c1.353,0,2.448-0.981,2.448-2.192S43.479,27.322,42.126,27.322z M2.949,5.397h39.177
								c1.353,0,2.448-0.981,2.448-2.192s-1.096-2.192-2.448-2.192H2.949C1.596,1.013,0.5,1.994,0.5,3.205S1.596,5.397,2.949,5.397z
								 M2.949,18.553h29.382c1.353,0,2.449-0.981,2.449-2.193c0-1.211-1.097-2.192-2.449-2.192H2.949c-1.353,0-2.449,0.981-2.449,2.192
								C0.5,17.571,1.596,18.553,2.949,18.553z" />
					  </g>
					</svg>
				</button>
			</SideBar>
		)
	}
}

export default SideBarMenu
