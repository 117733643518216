import React from "react"
import { Link } from "gatsby"
import SideBarMenu from "../sidebar/sidebar"
import Sticky from "react-sticky-el"
import "./header.scss"

const Header = () => {
  return (
    <header>
      <Sticky
        className="sticky-wrapper"
        stickyClassName="is-sticky"
        stickyStyle={{ transform: "unset", zIndex: "2" }}
      >
        <div className="header-top d-block d-md-none">
          <div className="container px-5 px-md-3">
            <p className="text-right mb-0">
              <a
                className="find-practioner"
                href="https://directory.narmtraining.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 511.999 511.999"
                  style={{ enableBackground: "new 0 0 511.999 511.999" }}
                  width="16px"
                  height="16px"
                >
                  <g>
                    <path
                      d="M508.874,478.708L360.142,329.976c28.21-34.827,45.191-79.103,45.191-127.309c0-111.75-90.917-202.667-202.667-202.667    S0,90.917,0,202.667s90.917,202.667,202.667,202.667c48.206,0,92.482-16.982,127.309-45.191l148.732,148.732    c4.167,4.165,10.919,4.165,15.086,0l15.081-15.082C513.04,489.627,513.04,482.873,508.874,478.708z M202.667,362.667    c-88.229,0-160-71.771-160-160s71.771-160,160-160s160,71.771,160,160S290.896,362.667,202.667,362.667z"
                      fill="#FFFFFF"
                    />
                  </g>
                </svg>
                <span className="ml-1">Find a Practitioner</span>
              </a>
            </p>
          </div>
        </div>
        <div className="menu-container">
          <div className="container px-5 px-md-3">
            <div className="row d-flex align-items-center">
              <div className="col-9 col-md-4">
                <div className="nav-brand">
                  <Link to="/">
                    <img
                      className="img-fluid"
                      src="/assets/img/logo.png"
                      alt="logo"
                    />
                  </Link>
                </div>
              </div>
              <div className="col-2 col-md-8 ml-auto">
                <ul className="list-unstyled header-menu d-flex align-items-center float-right mb-0">
                  <li className="mr-3 d-none d-md-inline">
                    <a
                      className="find-practioner"
                      href="https://directory.narmtraining.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        viewBox="0 0 511.999 511.999"
                        style={{ enableBackground: "new 0 0 511.999 511.999" }}
                        width="20px"
                        height="20px"
                      >
                        <g>
                          <path
                            d="M508.874,478.708L360.142,329.976c28.21-34.827,45.191-79.103,45.191-127.309c0-111.75-90.917-202.667-202.667-202.667    S0,90.917,0,202.667s90.917,202.667,202.667,202.667c48.206,0,92.482-16.982,127.309-45.191l148.732,148.732    c4.167,4.165,10.919,4.165,15.086,0l15.081-15.082C513.04,489.627,513.04,482.873,508.874,478.708z M202.667,362.667    c-88.229,0-160-71.771-160-160s71.771-160,160-160s160,71.771,160,160S290.896,362.667,202.667,362.667z"
                            fill="#FFFFFF"
                          />
                        </g>
                      </svg>
                      <span className="ml-2">Find a Practitioner</span>
                    </a>
                  </li>
                  <li>
                    <SideBarMenu />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Sticky>
    </header>
  )
}
export default Header
