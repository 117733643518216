import React from "react"
import { Link } from "gatsby"
import Sticky from "react-sticky-el"

const footer = props => {
  return (
    <footer className="component pb-0">
      <div className="footer-inner-container">
        <div className="container px-5 px-md-3">
          <div className="row">
            <div className="col-12 mb-4">
              <img src="/assets/img/logo-white.png" alt="logo" />
            </div>

            <div className="col-md-3 mb-4">
              <ul className="list-unstyled" id="footer-contact">
                <li>
                  <img
                    src="/assets/img/001-placeholder.svg"
                    height={18}
                    width={18}
                    alt="location"
                  />
                  <span>206 Mason St., Suite E Ukiah, CA USA, 95482</span>
                </li>
                <li>
                  <img
                    src="/assets/img/002-call.svg"
                    height={18}
                    width={18}
                    alt="phone"
                  />
                  <a href="tel:7076218945">707-621-8945</a>
                </li>
                <li>
                  <img
                    src="/assets/img/003-at.svg"
                    height={18}
                    width={18}
                    alt="email"
                  />
                  <a href="mailto:admin@NARMtraining.com">
                    admin@NARMtraining.com
                  </a>
                </li>
              </ul>
              <img
                className="img-fluid mb-4"
                src="/assets/img/accredation.png"
                alt="accredation"
              />
              <ul className="list-unstyled" id="footer-social">
                <li>
                  <a href="https://www.facebook.com/NARMTraining/">
                    <img
                      src="/assets/img/002-facebook.svg"
                      width={20}
                      height={20}
                      alt="facebook"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/http://twitter.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="/assets/img/001-twitter.svg"
                      width={20}
                      height={20}
                      alt="twitter"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/channel/UCezENiBJtJQwTAw2wNomLVg"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="/assets/img/003-youtube.svg"
                      width={20}
                      height={20}
                      alt="youtube"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/thenarmtraininginstitute/?hl=en"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="/assets/img/004-instagram.svg"
                      width={20}
                      height={20}
                      alt="instagram"
                    />
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-2">
                  <ul className="list-unstyled">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="/events">Events</a>
                    </li>
                    <li>
                      <a href="/contact">Contact</a>
                    </li>
                    <li>
                      <a
                        className="find-practioner"
                        href="https://directory.narmtraining.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Find a Practitioner
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-3">
                  <ul className="list-unstyled">
                    <li>
                      <Link className="h6 font-weight-bold" to="/trainings"><u>Trainings</u></Link>
                    </li>
                    <li>
                      <Link to="/online-learning/inner-circle">
                        Inner Circle
                      </Link>
                    </li>
                    <li>
                      <a
                        href="https://directory.narmtraining.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Video Training
                      </a>
                    </li>
                    <li>
                      <Link to="/obt2020">Online NARM Basics Training</Link>
                    </li>
                    <li>
                      <Link to="/schedule">North America Trainings</Link>
                    </li>
                    <li>
                      <a
                        href="http://drlaurenceheller.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        International Trainings
                      </a>
                    </li>
                    <li>
                      <Link to="/trainings/curriculum">
                        NARM Training Curriculum
                      </Link>
                    </li>
                    <li>
                      <Link to="/trainings/ceus/">CEU'S</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-4">
                  <ul className="list-unstyled">
                    <li>
                      <Link className="h6 font-weight-bold" to="/resources"><u>Resources</u></Link>
                    </li>
                    <li>
                      <Link to="/transformingtrauma">Transforming Trauma</Link>
                    </li>
                    <li>
                      <a
                        href="https://narm.kartra.com/page/ebooks"
                        target="_blank"
                        rel="noreferrer"
                      >
                        NARM Ebooks
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://narm.kartra.com/page/NARMWorksheet"
                        target="_blank"
                        rel="noreferrer"
                      >
                        NARM Clinical Protocol & Narm Personality Spectrum
                        Worksheet
                      </a>
                    </li>
                    <li>
                      <Link to="/resources">Podcasts, Books & Articles</Link>
                    </li>
                    <li>
                      <Link to="/blog">Blog</Link>
                    </li>
                    <li>
                      <Link to="/narm-student-resources">
                        Student Resources
                      </Link>
                    </li>
                    <li>
                      <a
                        href="https://directory.narmtraining.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Find a Practitioner
                      </a>
                    </li>
                    <li>
                      <a
                        href="/assets/img/pdf/Brand_Policy__(7.3.20).pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Brand Policy
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-3">
                  <ul className="list-unstyled">
                    <li>
                      <Link className="h6 font-weight-bold" to="/what-is-narm"><u>About Us</u></Link>
                    </li>
                    <li>
                      <Link to="/what-is-narm">What Is Narm?</Link>
                    </li>
                    <li>
                      <Link to="/faculty">Faculty</Link>
                    </li>
                    <li>
                      <Link to="/training-assistants">Training Assistants</Link>
                    </li>
                    <li>
                      <Link to="/staff">Staff</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-12 mt-5">
              <p className="small mb-0 desclaimer">
                Disclaimer: The NARM Training Institute is neither a regulatory
                nor licensing organization and therefore not sanctioned to
                certify, license, or otherwise bestow the legal authorization to
                practice as a mental health professional. You are responsible
                for working under your professional license and within your
                scope of practice.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id="copyright">
        <div className="container px-5 px-md-3">
          <div className="row align-items-center">
            <div className="col-12">
              <p className="py-3 mb-0">
                Copyright 2020 by NARM Training Institute LLC. All Rights
                Reserved. <Link to="/privacy-policy">Terms of Service</Link> | 
                <Link to="/privacy-policy">GDPR</Link> | 
                <Link to="/privacy-policy">Privacy Policy</Link> |{" "}
                <Link to="/sitemap.xml">Sitemap</Link>
              </p>
            </div>
            <div className="col-auto col-sm-2 h-100 d-none">
              <div id="gotop">
                <Link to="#___gatsby">
                  <img
                    className="img-fluid"
                    src="/assets/img/up-arrow.svg"
                    width={20}
                    height={20}
                    alt="go top"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default footer
